// Accept drag'n drop on file inputs
// Files dropped on an input are automatically uploaded
//
// Usage:
// <%= form_with(model: my_model, data: { controller: :droppable, "droppable-action": :handleDrop }) do |f| %>
//   <%= f.label :file %>
//   <%= f.file :file, "data-droppable-target": :input %>
//   <%= f.submit :file, "data-droppable-target": :submit %>
// <% end %>

import ApplicationController from "./application_controller"
import Droppable from "droppable"

export default class extends ApplicationController {
  static targets = ["input", "submit", "error"]
  static values = { maxFileSize: Number }

  connect() {
    this.droppable = new Droppable({
      element: this.input,
      acceptsMultipleFiles: this.multiple,
      appendStatusClasses: true,
      isClickable: false // if true, the accept attribute is ignored
    })
    this.droppable.onFilesDropped((files) => this.handleDrop(files))
  }

  disconnect() {
    this.droppable.destroy()
  }

  handleDrop(files) {
    let form = this.element
    let data = new FormData(form)
    this.clearAlert()
    if (this.multiple) {
      for (var i = 0; i < files.length; i++) {
        if (this.invalid(files[i])) {
          return
        }
        data.append(`${this.input_name}`, files[i])
      }
    } else {
      if (this.invalid(files[0])) {
        return
      }
      data.append(this.input_name, files[0])
    }
    Rails.disableElement(this.submit)
    Rails.ajax({
      type: form.getAttribute("method"),
      url: `${form.getAttribute("action")}.js`,
      data: data,
    })
  }

  invalid(file) {
    let extension = file.name.match(/(\.\w+$)/)[1]
    if (this.accept && !(this.accept.includes(file.type) || this.accept.includes(extension))) {
      this.addAlert(`Le fichier <strong>${file.name}</strong> ne peut pas être envoyé car c'est un <strong>${extension}</strong>. Merci de sélectionner des fichiers de type : <strong>${this.accept.filter((item)=> item.match(/^(\.)/)).join(", ")}</strong>.`)
      return true
    }
    // maxFileSizeValue defaults 0 if unset
    if (this.maxFileSizeValue > 0 && file.size > this.maxFileSizeValue) {
      this.addAlert(`Le fichier <strong>${file.name}</strong> est trop lourd : <strong>${this.humanFileSize(file.size)}</strong>. Le poids maximum autorisé est : <strong>${this.humanFileSize(this.maxFileSizeValue)}</strong>.`)
      return true
    }
  }

  humanFileSize(number) {
    if (number < 1024) {
      return `${number} octets`
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} Ko`
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} Mo`
    }
  }

  clearAlert() {
    if (this.hasErrorTarget) {
      this.errorTarget.innerHTML = ""
    }
  }

  addAlert(message) {
    if (message && this.hasErrorTarget) {
      this.errorTarget.innerHTML += `<p>${message}</p>`
    }
  }

  checkFile() {
    this.clearAlert()
    for(let i = 0; i < this.input.files.length; i++) {
      this.invalid(this.input.files[i])
    }
  }

  get input() {
    if (this.hasInputTarget) {
      return this.inputTarget
    } else {
      return this.element.querySelector("input[type=file]")
    }
  }

  get submit() {
    if (this.hasSubmitTarget) {
      return this.submitTarget
    } else {
      return this.element.querySelector("button[type=submit]")
    }
  }

  get multiple() {
    return this.input.hasAttribute("multiple")
  }

  get input_name() {
    return this.input.getAttribute("name")
  }

  get accept() {
    return this.input.getAttribute("accept")?.replace("image/*", "image/jpg,image/jpeg,image/png")?.replace("application/pdf", ".pdf")?.split(",")
  }
}
