// Allow adding/removing tags from a list
//
// Usage:
// <div data-controller="tags">
//   <button hidden type="button" class="btn" data-action="number#decrement" data-number-target="button"> - </button>
//   <%= f.number_field :tag_names, class: "form-control", "data-tags-target": :input %>
//   <button type="button" class="btn btn-outline-secondary" data-action="number#increment" data-number-target="button"> + </button>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "tag", "template"]

  connect() {
    this.sync()
  }

  sync(e = null) {
    for(let name of this.tag_names) {
      let btn = this.findByName(name)
      if (btn) { // Activate existing tags
        btn.classList.add("active")
      } else { // Create new tags
        btn = this.templateTarget.innerHTML.replace(/TAG_NAME/, name)
        this.templateTarget.insertAdjacentHTML("beforebegin", btn)
      }
    }
    // Deactivate removed tags
    this.tagTargets.forEach((tag) => {
      if (!this.tag_names.includes(tag.innerText.trim())) {
        tag.classList.remove("active")
      }
    })
    // Add spaces after commas if necessary (cosmetic)
    this.inputTarget.value = this.inputTarget.value.replace(/,(\w)/g, ", $1")
  }

  toggle(e) {
    const name = e.target.innerText.trim()
    if (this.tag_names.includes(name)) {
      this.remove(name)
      e.target.classList.remove("active")
    } else {
      this.append(name)
      e.target.classList.add("active")
    }
  }

  append(name) {
    let names = this.tag_names
    names.push(name)
    this.value = names
  }

  remove(name) {
    let names = this.tag_names
    names.splice(this.tag_names.indexOf(name), 1)
    this.value = names
  }

  findByName(name) {
    return this.tagTargets.find(tag => tag.innerText.trim() == name )
  }

  get tag_names() {
    if (this.inputTarget.value.indexOf(",") > 1) {
      let names = this.inputTarget.value
      names = names.slice(0, names.lastIndexOf(",")) // Ignore text after the last comma
      return names.split(",").map((name) => name.trim()).filter((name) => name.length > 0) || []
    } else {
      return []
    }
  }

  set value(names) {
    if (names === undefined) {
      names = []
    }
    if (names.length > 0) {
      this.inputTarget.value = names.join(", ") + ", "
    } else {
      this.inputTarget.value = ""
    }
  }
}
