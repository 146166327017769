// Add left/right buttons to scroll through a list of tabs

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["tab", "prevButton", "nextButton"]
  static classes = ["hide"]

  connect() {
    this.resize()
  }

  disconnect() {
    this.teardown()
  }

  resize() {
    if (window.innerWidth < this.applyUnder) {
      this.setup()
    } else {
      this.teardown()
    }
  }

  setup() {
    if (!this.element.classList.contains("tabs-applied")) {
      this.element.classList.add("tabs-applied")
      this.prevButtonTarget.removeAttribute("hidden")
      this.nextButtonTarget.removeAttribute("hidden")
      this.activeTab = this.tabTargets.filter((tab) =>
        tab.classList.contains("active")
      )[0]
      this.toggleTabVisibility()
    }
  }

  teardown() {
    if (this.element.classList.contains("tabs-applied")) {
      this.prevButtonTarget.setAttribute("hidden", "hidden")
      this.nextButtonTarget.setAttribute("hidden", "hidden")
      this.tabTargets.forEach((tab) => this.toggleVisibility(tab, true))
      this.element.classList.remove("tabs-applied")
    }
  }

  showPrevTab() {
    this.activeTab = this.prevTab
    this.toggleTabVisibility()
  }

  showNextTab() {
    this.activeTab = this.nextTab
    this.toggleTabVisibility()
  }

  toggleTabVisibility() {
    this.tabTargets.forEach((tab) =>
      this.toggleVisibility(tab, tab == this.activeTab)
    )
  }

  get applyUnder() {
    if (!this.totalTabWidth) {
      this.totalTabWidth =
        this.tabTargets
          .map((tab) => tab.scrollWidth)
          .reduce((acc, val) => acc + val) + 30 // window padding
    }
    return this.totalTabWidth
  }

  get activeTab() {
    return this.tabTargets[this.index]
  }

  set activeTab(tab) {
    this.index = this.tabIndex(tab)
  }

  get prevTab() {
    if (this.index == 0) {
      return this.tabTargets[this.tabTargets.length - 1]
    } else {
      return this.tabTargets[this.index - 1]
    }
  }

  get nextTab() {
    if (this.index == this.tabTargets.length - 1) {
      return this.tabTargets[0]
    } else {
      return this.tabTargets[this.index + 1]
    }
  }

  tabIndex(tab) {
    return this.tabTargets.indexOf(tab)
  }

  toggleDisable(button, disabled) {
    if (disabled) {
      button.setAttribute("disabled", "disabled")
      button.classList.add("disabled")
    } else {
      button.removeAttribute("disabled")
      button.classList.remove("disabled")
    }
  }

  toggleVisibility(el, visible) {
    if (visible) {
      el.classList.remove(this.hideClass)
    } else {
      el.classList.add(this.hideClass)
    }
  }
}
