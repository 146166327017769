// Handle placeholders in Trix editor windows
// Code adapted from https://github.com/gorails-screencasts/at-mentions-with-action-text/
//
// Usage:
//  <%= f.rich_text_area :content, class: "form-control", data: { controller: "placeholders", "placeholder-target": "field" } %>

import ApplicationController from "./application_controller"
import Tribute from "tributejs"
import Trix from "trix"
const PLACEHOLDERS_URL = "/placeholders.json"

export default class extends ApplicationController {
  connect() {
    Rails.ajax({
      url: PLACEHOLDERS_URL,
      type: "get",
      error: this.network_error,
      success: this.initializeTribute.bind(this),
    })
  }

  disconnect() {
    if (this.tribute) {
      this.tribute.detach(this.element)
    }
  }

  initializeTribute(placeholders) {
    this.tribute = new Tribute({
      allowSpaces: true,
      requireLeadingSpace: true,
      values: placeholders,
      lookup: "name",
      menuItemLimit: 40,
    })
    this.tribute.attach(this.element)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.element.addEventListener("tribute-replaced", this.replaced)
  }

  replaced(event) {
    let placeholder = event.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: placeholder.sgid,
      content: placeholder.content,
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }

  get editor() {
    return this.element.editor
  }
}
