// Code from https://www.donnfelker.com/disable-attachments-in-the-trix-editor/
// See also: https://github.com/basecamp/trix/issues/604#issuecomment-600974875
// Usage: data-controller="trix-disable-uploads" on an element surrounding the rich_text_editor

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("trix-initialize", this.removeUploadButton)
    this.element.addEventListener("trix-attachment-add", this.preventCopyPaste)
    this.element.addEventListener("trix-file-accept", this.preventDragDrop)
  }

  disconnect() {
    this.element.removeEventListener("trix-initialize", this.removeUploadButton)
    this.element.removeEventListener("trix-attachment-add", this.preventCopyPaste)
    this.element.removeEventListener("trix-file-accept", this.preventDragDrop)
  }

  removeUploadButton(e = null) {
    const uploadButton = e.target.querySelector(".trix-button-group--file-tools")
    // null check hack: trix-initialize gets called twice for some reason, sometimes it is null :shrug:
    uploadButton?.remove()
  }

  preventCopyPaste(e = null) {
    if (!e.attachment.file) {
      e.attachment.remove()
    }
  }

  preventDragDrop(e = null) {
    e.preventDefault()
  }
}
