// Decrement/increment an input field
//
// Usage:
// <div data-controller="number">
//   <button hidden type="button" class="btn" data-action="number#decrement" data-number-target="button"> - </button>
//   <%= f.number_field :quantity, class: "form-control", min: 0, "data-number-target": :input %>
//   <button hidden type="button" class="btn" data-action="number#increment" data-number-target="button"> + </button>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "button"]

  connect() {
    this.buttonTargets.forEach((el) => el.removeAttribute("hidden"))
  }

  decrement() {
    if (this.is_disabled) {
      return
    }
    if (this.min) {
      this.val = Math.max(this.min, this.val - this.step)
    } else {
      this.val -= this.step
    }
  }

  increment() {
    if (this.is_disabled) {
      return
    }
    if (this.max) {
      this.val = Math.min(this.max, this.val + this.step)
    } else {
      this.val += this.step
    }
  }

  get is_disabled() {
    return this.inputTarget.disabled || false
  }

  get min() {
    return parseFloat(this.inputTarget.getAttribute("min")) || null
  }

  get max() {
    return parseFloat(this.inputTarget.getAttribute("max")) || null
  }

  get step() {
    return parseFloat(this.inputTarget.getAttribute("step")) || 1.0
  }

  get val() {
    return parseFloat(this.inputTarget.value.replace(",", ".")) || null
  }

  set val(val) {
    if (val === undefined) {
      val = ""
    }
    val = val.toString(10).replace(".", ",")
    this.inputTarget.value = val
    Rails.fire(this.inputTarget, "input")
  }
}
