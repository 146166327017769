// Sort elements
//
// Usage:
// <ol data-controller="sortable">
//   <li data-url="/elements/1/move_to/">>Element 1</li>
//   <li data-url="/elements/2/move_to/">Element 2
//      <ol data-controller="sortable">
//        Optional nested list…
//      </ol>
//   </li>
// </ol>

import ApplicationController from "./application_controller"
import Sortable from "sortablejs"

export default class extends ApplicationController {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      animation: 250,
      group: "nested",
      handle: ".sortable-handle",
      fallbackOnBody: true,
      swap: true, // Enable swap plugin
      swapClass: "bg-secondary", // The class applied to the hovered swap item
      swapThreshold: 0.65,
    })
  }

  disconnect() {
    this.sortable = null
  }

  start(event) {
    document.body.classList.add("sorting")
  }

  end(event) {
    document.body.classList.remove("sorting")
    let url = this.element.dataset.url.replace(":id", event.item.dataset.id)
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    data.append("parent_id", event.to.dataset.parent || "")
    Rails.ajax({
      type: "PATCH",
      url: url,
      data: data,
    })
  }
}
