// Usage: data-controller="trix-single-line" on an element surrounding the rich_text_editor

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("trix-change", this.preventNewLines)
  }

  disconnect() {
    this.element.removeEventListener("trix-change", this.preventNewLines)
  }

  preventNewLines(e) {
    const editor = e.target.editor
    const text = editor.getDocument().toString()
    if (text.length == 1) {
      return
    }
    let position = text.lastIndexOf("\n", text.length - 2)
    if (position == -1) {
      return
    }
    editor.setSelectedRange([position, position + 1])
    editor.deleteInDirection("forward")
  }
}
