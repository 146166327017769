// DO NOT AUTOGENERATE THIS FILE or the first few lines will be wrong

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import ApplicationController from "./application_controller"
Stimulus.register("application", ApplicationController)

import AutogrowController from "./autogrow_controller"
Stimulus.register("autogrow", AutogrowController)

import AutosaveController from "./autosave_controller"
Stimulus.register("autosave", AutosaveController)

import CompanyController from "./company_controller"
Stimulus.register("company", CompanyController)

import DateController from "./date_controller"
Stimulus.register("date", DateController)

import DestroyController from "./destroy_controller"
Stimulus.register("destroy", DestroyController)

import DroppableController from "./droppable_controller"
Stimulus.register("droppable", DroppableController)

import ModalController from "./modal_controller"
Stimulus.register("modal", ModalController)

import NestedFieldsController from "./nested_fields_controller"
Stimulus.register("nested-fields", NestedFieldsController)

import NotificationController from "./notification_controller"
Stimulus.register("notification", NotificationController)

import NumberController from "./number_controller"
Stimulus.register("number", NumberController)

import OrderController from "./order_controller"
Stimulus.register("order", OrderController)

import ParticipationController from "./participation_controller"
Stimulus.register("participation", ParticipationController)

import PasswordToggleController from "./password_toggle_controller"
Stimulus.register("password-toggle", PasswordToggleController)

import PlaceholdersController from "./placeholders_controller"
Stimulus.register("placeholders", PlaceholdersController)

import PopoverController from "./popover_controller"
Stimulus.register("popover", PopoverController)

import SchemeSelectorController from "./scheme_selector_controller"
Stimulus.register("scheme-selector", SchemeSelectorController)

import SortableController from "./sortable_controller"
Stimulus.register("sortable", SortableController)

import TableScrollController from "./table_scroll_controller"
Stimulus.register("table-scroll", TableScrollController)

import TabsController from "./tabs_controller"
Stimulus.register("tabs", TabsController)

import TagsController from "./tags_controller"
Stimulus.register("tags", TagsController)

import TimeController from "./time_controller"
Stimulus.register("time", TimeController)

import ToggleOtherController from "./toggle_other_controller"
Stimulus.register("toggle-other", ToggleOtherController)

import TrixDisableUploadsController from "./trix_disable_uploads_controller"
Stimulus.register("trix-disable-uploads", TrixDisableUploadsController)

import TrixSingleLineController from "./trix_single_line_controller"
Stimulus.register("trix-single-line", TrixSingleLineController)
