import Trix from "trix"

document.addEventListener("trix-before-initialize", () => {
  // Trix.config.blockAttributes.default.tagName = "p"
  Trix.config.blockAttributes.heading1.tagName = "h2"

  Trix.config.lang.attachFiles = "Ajouter une image (jpeg ou png)"
  Trix.config.lang.bold = "Gras"
  Trix.config.lang.bullets = "Liste"
  Trix.config.lang.byte = "Octet"
  Trix.config.lang.bytes = "Octets"
  Trix.config.lang.captionPlaceholder = "Légende…"
  Trix.config.lang.code = "Code"
  Trix.config.lang.heading1 = "Titre"
  Trix.config.lang.indent = "Augmenter le retrait"
  Trix.config.lang.italic = "Italique"
  Trix.config.lang.link = "Lien"
  Trix.config.lang.numbers = "Liste numérotée"
  Trix.config.lang.outdent = "Réduire le retrait"
  Trix.config.lang.quote = "Citation"
  Trix.config.lang.redo = "Rétablir"
  Trix.config.lang.remove = "Supprimer"
  Trix.config.lang.strike = "Barré"
  Trix.config.lang.undo = "Annuler"
  Trix.config.lang.unlink = "Supprimer le lien"
  Trix.config.lang.url = "Lien"
  Trix.config.lang.urlPlaceholder = "Saisir adresse URL…"
  Trix.config.lang.GB = "Go"
  Trix.config.lang.KB = "Ko"
  Trix.config.lang.MB = "Mo"
  Trix.config.lang.PB = "Po"
  Trix.config.lang.TB = "To"
})

/*
Turn lines starting with dashes or stars into bullet lists and lines starting with "1. " into numbered lists.
Works within nested lists but skips headings, code blocks and pasted text.
*/
document.addEventListener("trix-change", function (event) {
  const editor = event.target.editor
  const position = editor.getPosition()
  if (
    position < 2 ||
    editor.attributeIsActive("code") ||
    editor.attributeIsActive("heading1")
  ) {
    return
  }
  let text = editor
    .getDocument()
    .toString()
    .substring(position - 4, position)
  const bullet = /\n[*-] /
  const number = /\n1\. /
  let type,
    offset = null
  if (position < 4) {
    text = "\n" + text
  }

  if (bullet.test(text)) {
    offset = 2
    type = "bullet"
  } else if (number.test(text)) {
    offset = 3
    type = "number"
  }
  if (type) {
    editor.recordUndoEntry("autolist")
    editor.setSelectedRange([position - offset, position])
    editor.deleteInDirection("forward")
    editor.activateAttribute(type)
  }
})

/*
Accept only images in Trix editors
https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6.html
*/
// Prevent drag-drop
window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = ["image/jpeg", "image/png"]
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Seules les images peuvent être insérées (format jpeg ou png).")
  }
})
// Prevent copy-paste
window.addEventListener("trix-attachment-add", function (event) {
  if (!event.attachment.file) {
    event.preventDefault()
  }
})
