// Reorder elements

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["item", "input", "button"]

  connect() {
    this.buttonTargets.forEach((el) => el.removeAttribute("hidden"))
    this.updateOrderInputs()
  }

  reorder() {
    this.updateOrderInputs()
  }

  up(e){
    this.change(e, true)
  }

  down(e){
    this.change(e, false)
  }

  change(e, up) {
    const itemsTotal = this.itemTargets.length
    if (itemsTotal == 1) {
      return // Nothing to do
    }
    const item = e.target.closest(".js-order-item")
    const index = this.indexOf(item)
    if (up) {
      if (index == 0) {
        this.move(item, "after", this.itemTargets[itemsTotal - 1])
      } else {
        this.move(item, "before", this.itemTargets[index - 1])
      }
    } else {
      if (index == itemsTotal - 1) {
        this.move(item, "before", this.itemTargets[0])
      } else {
        this.move(item, "after", this.itemTargets[index + 1])
      }
    }
    this.updateOrderInputs()
  }

  move(source, position, target) {
    target.insertAdjacentElement(position == "before" ? "beforebegin" : "afterend", source)
    Rails.yellow_fade(source)
  }

  updateOrderInputs() {
    this.inputTargets.forEach((input, i) => input.value = i + 1)
  }

  indexOf(item) {
    return this.itemTargets.indexOf(item)
  }

  get lastItem() {
    return this.itemTargets[this.itemTargets.length]
  }

  get container() {
    return this.element.closest(".js-order-container")
  }
}
