// Select color scheme
//
// Usage:
// <div class="btn-group" data-controller="scheme-selector">
//   <input type="radio" name="scheme" class="btn-check" id="scheme-auto" autocomplete="off"  data-scheme-selector-target="auto" data-action="scheme-selector#toggle" />
//   <label class="btn btn-sm btn-outline-secondary border-0" for="scheme-auto"><%= icon :adjust %></label>
//   <input type="radio" name="scheme" class="btn-check" id="scheme-light" autocomplete="off" data-scheme-selector-target="light" data-action="scheme-selector#toggle" />
//   <label class="btn btn-sm btn-outline-secondary border-0" for="scheme-light"><%= icon :sun %></label>
//   <input type="radio" name="scheme" class="btn-check" id="scheme-dark" autocomplete="off"  data-scheme-selector-target="dark" data-action="scheme-selector#toggle" />
//   <label class="btn btn-sm btn-outline-secondary border-0" for="scheme-dark"><%= icon :moon %></label>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["auto", "light", "dark"]

  connect() {
    this.scheme = this.preferredScheme
    this.setToggler()
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.handleGlobalChange.bind(this))
  }

  disconnect() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", this.handleGlobalChange.bind(this))
  }

  handleGlobalChange() {
    if (this.storedScheme == "auto") {
      this.scheme = this.isDark ? "dark" : "light"
    }
  }

  setToggler() {
    switch (this.storedScheme) {
      case "light":
        this.lightTarget.checked = true
        break
      case "dark":
        this.darkTarget.checked = true
        break
      default:
        this.autoTarget.checked = true
        break
    }
  }

  toggle(e) {
    const scheme = e.target.value
    this.scheme = scheme
    localStorage.setItem("color-scheme", scheme)
  }

  get isDark() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
  }

  get storedScheme() {
    return localStorage.getItem("color-scheme") || "auto"
  }

  get preferredScheme() {
    return this.storedScheme || (this.isDark ? "dark" : "light")
  }

  set scheme(scheme) {
    if (scheme === "auto") {
      scheme = this.isDark ? "dark" : "light"
    }
    document.documentElement.setAttribute("data-bs-theme", scheme)
  }
}
