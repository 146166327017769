// Toggle password field visibility
//
// Usage:
// <div class="input-group" data-controller="password-toggle">
//   <input type="password" class="form-control" data-target="password-toggle.password">
//   <div class="input-group-append">
//     <button type="button" class="btn btn-outline-secondary" hidden data-target="password-toggle.toggler" data-action="password-toggle#toggle">Toggle password</button>
//   </div>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["password", "toggler"]

  connect() {
    this.togglerTarget.removeAttribute("hidden")
  }

  toggle() {
    if (this.passwordVisible()) {
      this.passwordTarget.type = "password"
      this.togglerTarget.classList.remove("active")
    } else {
      this.passwordTarget.type = "text"
      this.togglerTarget.classList.add("active")
    }
  }

  passwordVisible() {
    return this.passwordTarget.type == "text"
  }
}
