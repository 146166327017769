// Assist filling in company form fields

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["siret"]

  autofill_vat() {
    Rails.ajax({
      type: "GET",
      url: `/companies/vat_from_siret.js?siret=${this.siretTarget.value.replaceAll(/\W/ig, "")}`
    })
  }
}
