// Disable form fields when the container is marked for destruction
//
// Usage:
// <div data-controller="destroy">
//   <input type="checkbox" data-destroy-target="input" data-action="destroy#toggle" />
//   or
//   <input type="hidden" name="_destroy" value="0" />
//   <button type="button" hidden data-destroy-target="remove" data-action="destroy#remove">Remove</button>
//   <button type="button" hidden data-destroy-target="restore" data-action="destroy#restore">Restore</button>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "remove", "restore"]

  connect() {
    this.toggleDisplay()
  }

  toggle() {
    this.toggleDisplay()
  }
  remove(event) {
    event.preventDefault()
    this.value = 1
    this.toggleDisplay()
  }
  restore(event) {
    event.preventDefault()
    this.value = 0
    this.toggleDisplay()
  }

  toggleDisplay() {
    if (!this.hasInputTarget) {
      return
    }
    if (this.destroyed) {
      this.element.classList.add("nested-fields-delete")
      this.disableInputs()
      if (this.hasRemoveTarget) {
        this.hide(this.removeTarget)
      }
      if (this.hasRestoreTarget) {
        this.unhide(this.restoreTarget)
      }
    } else {
      this.element.classList.remove("nested-fields-delete")
      this.enableInputs()
      if (this.hasRemoveTarget) {
        this.unhide(this.removeTarget)
      }
      if (this.hasRestoreTarget) {
        this.hide(this.restoreTarget)
      }
    }
  }
  enableInputs() {
    this.inputs.forEach((input) => {
      input.removeAttribute("readonly")
    })
  }
  disableInputs() {
    this.inputs.forEach((input) => {
      input.setAttribute("readonly", "readonly")
    })
  }
  hide(element) {
    element.setAttribute("hidden", true)
  }
  unhide(element) {
    element.removeAttribute("hidden")
  }

  // Getters
  get inputs() {
    return Rails.$$("input, select, textarea", this.element).filter(
      (input) => input != this.inputTarget
    )
  }
  get value() {
    return parseInt(this.inputTarget.value)
  }
  set value(val) {
    this.inputTarget.value = val
  }
  get destroyed() {
    if (this.inputTarget.type.toLowerCase() == "checkbox") {
      return this.inputTarget.checked
    } else {
      return this.value == 1
    }
  }
}
