// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// Usage:
// 1. Create a nested field partial named [association_singular]_fields
// 2. Call the nested_fields partial, passing the current form builder and the association name
// <%= render "nested_fields", f: f, association: :association_singular %>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["template"]

  add(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    )
    this.templateTarget.insertAdjacentHTML("beforebegin", content)
    this.setFocus()
    Rails.fire(this.element, "nested-insert")
  }

  setFocus() {
    let input = Rails.$(
      "input:not([type=hidden]), textarea, select",
      this.templateTarget.previousElementSibling
    )
    if (input) {
      input.focus()
    }
  }
}
