// Handle modals
//
// Usage:
//  Adding a modal using a JS response:
//    Rails.fire(window, "modal:add", "<%= j modal_content.chomp.html_safe %>"`)
//  Closing a modal using a JS response:
//    Rails.fire(window, "modal:remove")
//  Modal will be shown automatically, closing and scrolling is handled by Bootstrap itself

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  disconnect() {
    this.element.classList.remove("modal-open")
    Rails.remove_elements(".modal, .modal-backdrop")
  }

  add(event) {
    this.remove()
    this.addModal(event.detail)
  }

  remove() {
    let element = Rails.$(".modal")
    let modal
    if (element && (modal = bootstrap.Modal.getInstance(element))) {
      modal.hide()
    }
  }

  addModal(content) {
    let element = document.createRange().createContextualFragment(content)
    this.element.appendChild(element)
    element = this.element.lastChild
    new bootstrap.Modal(element, this.options(element)).show()
    // Remove modal from DOM after hiding it
    element.addEventListener("hidden.bs.modal", this.removeModal)
  }

  removeModal(event) {
    let modal = event.target
    modal.parentNode.removeChild(modal)
  }

  options(element) {
    return {
      keyboard: element.getAttribute("data-bs-keyboard") != "false",
      backdrop: element.getAttribute("data-bs-backdrop") || true,
    }
  }
}
