// Autosave forms using a keyboard shortcut
// Persisted objects are updated
// New forms are submitted if all required fields are
//
// Usage:
//  add `id: dom_id(model), data: { controller: :autosave }` to form_with
//  add `include Autosaveable` in the associated [Model]Controller

import hotkeys from "hotkeys-js"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    hotkeys(this.shortcuts, this.autosave.bind(this))
    hotkeys.filter = function (event) {
      return true
    } // Enable hotkeys on form fields (default is false)
  }

  disconnect() {
    hotkeys.unbind(this.shortcuts)
  }

  autosave(event) {
    event.preventDefault()
    if (this.is_submittable) {
      this.toggle_activity(false)
      Rails.ajax({
        url: this.action,
        type: this.method,
        data: this.data,
        error: this.notify_network_error,
        complete: this.toggle_activity.bind(this),
      })
    }
  }

  toggle_activity(off = true) {
    if (off || this.submit_button.disabled) {
      Rails.enableElement(this.submit_button)
    } else {
      Rails.disableElement(this.submit_button)
    }
  }

  get is_persisted() {
    return this.method == "PATCH"
  }

  get shortcuts() {
    return "ctrl+s,command+s"
  }

  get action() {
    return this.element.getAttribute("action")
  }

  get method() {
    return Rails.$("input[name=_method][value=patch]", this.element)
      ? "PATCH"
      : "POST"
  }

  get data() {
    return new FormData(this.element)
  }

  get submit_button() {
    return Rails.$("button[type=submit]", this.element)
  }

  get is_submittable() {
    return "reportValidity" in this.element
      ? this.element.reportValidity()
      : this.element.checkValidity()
  }
}
