import "./customized/trix"
import "@rails/actiontext"
import "@rails/activestorage"

import Turbolinks from "turbolinks"
Turbolinks.start()

// Make Rails available globally
import Rails from "@rails/ujs"
window.Rails = Rails
Rails.start()

// make Bootstrap available globally
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import "./controllers"

Rails.$ = function (selector, container = document) {
  return container.querySelector(selector)
}

Rails.$$ = function (selector, container = document) {
  return new Array(...container.querySelectorAll(selector))
}

Rails.remove_elements = function (selector, container = document) {
  Rails.$$(selector, container).forEach((element) =>
    element.parentNode.removeChild(element)
  )
}

Rails.replace = function (selector, contents) {
  Rails.$$(selector).forEach(function (el) {
    el.outerHTML = contents
  })
}

Rails.yellow_fade = function (targets) {
  if (typeof targets === "string") {
    targets = Rails.$$(targets)
  } else if (!targets?.length) {
    targets =[targets]
  }
  targets.forEach(function (element) {
    element.classList.add("yellow-fade")
    setTimeout(() => element.classList.remove("yellow-fade"), 1000)
  })
}

Rails.sort = function (selector) {
  Rails.$$(selector).forEach(function (el) {
    Array.from(el.children)
      .sort((a, b) => {
        return a.dataset.sort.localeCompare(b.dataset.sort)
      })
      .forEach((e) => el.appendChild(e))
  })
}
