// Handle Bootstrap popovers

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["trigger"]

  connect() {
    this.triggers.forEach((el) => bootstrap.Popover.getOrCreateInstance(el))
  }

  disconnect() {
    this.triggers.forEach((el) => bootstrap.Popover.getInstance(el)?.dispose())
  }

  triggerTargetConnected() {
    this.connect()
  }

  get triggers() {
    return Rails.$$('[data-bs-toggle="popover"]')
  }
}
