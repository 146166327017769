// Decrement/increment a date input field
//
// Usage:
//  When the controller holds only one input,
//  set `"data-date-target": :input` on it
//
// <div data-controller="date">
//   <button type="button" class="btn" data-date-action="date#decrement"> &lt; </button>
//   <%= f.date_field :date, class: "form-control", "data-date-target": :input %>
//   <button type="button" class="btn" data-date-action="date#increment"> &gt; </button>
// </div>
//
// When the controller contains more than one input,
// pass the target id using data-date-value
// <div data-controller="date">
//   <button type="button" class="btn" data-action="date#decrement" data-date-value="#start_date_id"> &lt;</button>
//   <%= f.date_field :start_date, class: "form-control", id: :start_date_id %>
//   <button type="button" class="btn" data-action="date#increment" data-date-value="#start_date_id"> &gt;</button>
//   <button type="button" class="btn" data-action="date#decrement" data-date-value="#end_date_id"> &lt;</button>
//   <%= f.date_field :end_date, class: "form-control", id: :end_date_id %>
//   <button type="button" class="btn" data-action="date#increment" data-date-value="#end_date_id"> &gt;</button>
// </div>

import dayjs from "dayjs"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input"]

  decrement(e) {
    this.changeValue(e, false)
    e.preventDefault()
  }

  increment(e) {
    this.changeValue(e, true)
    e.preventDefault()
  }

  changeValue(e, up) {
    let unit = this.unit(e)
    let input = this.hasInputTarget
      ? this.inputTarget
      : this.$(e.currentTarget.dataset["dateValue"])
    let value = dayjs(input.value || new Date())
    input.value = (up ? value.add(1, unit) : value.subtract(1, unit)).format(
      "YYYY-MM-DD"
    )
  }

  unit(e) {
    if (e && (e.ctrlKey || e.metaKey)) {
      return "year"
    } else if (e && e.shiftKey) {
      return "month"
    } else {
      return "day"
    }
  }
}
