// Decrement/increment a time input field
//
// Usage:
// <div data-controller="time">
//   <button hidden type="button" class="btn" data-action="time#decrement" data-number-target="button"> - </button>
//   <%= f.number_field :quantity, class: "form-control", min: 0, "data-time-target": :input %>
//   <button hidden type="button" class="btn" data-action="time#increment" data-number-target="button"> + </button>
// </div>

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input"]

  decrement(e) {
    e.preventDefault()
    let new_value = this.current - this.step(e)
    this.val = new_value < this.min ? this.min : new_value
  }

  increment(e) {
    e.preventDefault()
    let new_value = this.current + this.step(e)
    this.val = new_value > this.max ? this.max : new_value
  }

  parse_time_from(value) {
    let values = (value || "00:00").split(":")
    return parseInt(values[0]) * 60 + parseInt(values[1])
  }

  format(value) {
    // Convert minutes to milliseconds then extract HH:MM from the formatted date
    return new Date(value * 60 * 1000).toISOString().substring(11, 11 + 5)
  }

  step(e) {
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      return 15
    } else {
      return parseInt(this.inputTarget.getAttribute("step")) || 60
    }
  }

  get min() {
    return this.parse_time_from(
      this.inputTarget.getAttribute("min") || "00:00"
    )
  }

  get max() {
    return this.parse_time_from(
      this.inputTarget.getAttribute("max") || "24:00"
    )
  }

  get val() {
    return (
      this.inputTarget.value ||
      (!!this.inputTarget.name.match(/start/) ? "09:00" : "18:00")
    )
  }

  get current() {
    return this.parse_time_from(this.val)
  }

  set val(value) {
    if (value === undefined) {
      value = ""
    }
    // Turn minutes into a formatted datetime, then keep only the hh:mm part
    this.inputTarget.value = this.format(value)
    Rails.fire(this.inputTarget, "input")
  }
}
