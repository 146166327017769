// Automatically set default participation price

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["people", "amount"]
  static values = {
    priceFor: Number,
    price: String // French notation numbers need special casting
  }

  connect() {
    this.updateDefaultAmount()
  }

  updateDefaultAmount() {
    if (!this.canRun) {
      return
    }
    this.defaultAmount = this.groups * this.basePrice
  }

  get people() {
    return parseInt(this.peopleTarget.value, 10)
  }

  get groups() {
    return Math.ceil(this.people / this.priceForValue)
  }

  get basePrice() {
    return parseFloat(this.priceValue.replaceAll(" ", "").replaceAll(",", "."))
  }

  set defaultAmount(val) {
    this.amountTarget.placeholder = new Intl.NumberFormat('fr-FR').format(val)
  }

  get canRun() {
    return this.hasPeopleTarget && this.hasAmountTarget && !Number.isNaN(this.basePrice)
  }
}
