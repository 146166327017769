// Notification controller
//
// Usage:
//   Option #1
//    Rails.fire(window, "notify", "[toast HTML content]")
//   Option #2
//    Rails.fire(window, "ajax:error")

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  disconnect() {
    Rails.remove_elements(".toast")
  }

  addMessage(event) {
    if (event.detail) {
      this.addToast(event.detail)
    }
  }

  networkFailure() {
    this.addToast(
      this.createToast("danger", "Erreur", "Le serveur ne répond pas…")
    )
  }

  addToast(content) {
    let element = document.createRange().createContextualFragment(content)
    this.element.appendChild(element)
    let toast = this.element.lastChild
    let options = {
      animation: toast.getAttribute("data-bs-animation") != "false",
      autohide: toast.getAttribute("data-bs-autohide") != "false",
      delay: toast.getAttribute("data-bs-delay") || 5000,
    }
    new bootstrap.Toast(toast, options).show()
    toast.addEventListener("hidden.bs.toast", this.removeToast)
  }

  removeToast(event) {
    let toast = event.target
    toast.parentNode.removeChild(toast)
  }

  createToast(type, title, message) {
    let classes = `bg-${type} ${
      type == "success" || type == "danger" ? "text-white" : ""
    }`
    return `
    <div class="toast ${classes}" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="<%= autohide %>">
      <div class="toast-body">
        <strong>${title}</strong>
        ${message}
      </div>
      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Fermer"></button>
    </div>`
  }
}
